(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-window-size/use-window-size.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-window-size/use-window-size.js');
"use strict";



const {
  useState,
  useEffect
} = React;
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);
  return windowSize;
}
setGlobal('svs.components.lbUtils.useWindowSize', useWindowSize);

 })(window);